<template>
  <!-- template for the modal component -->
  <div class="c-modal">
    <div class="c-modal__wrapper" @click.self="$emit('close')">
      <div
        class="c-modal__container"
        :style="max_width ? `max-width: ${max_width}px` : ``"
      >
        <div class="c-modal__container-inner">
          <div class="c-modal__header">
            <h3 class="c-modal__header-text">
              <slot name="header"> default header </slot>
            </h3>
            <img
              class="c-modal__close-icon"
              @click="$emit('close')"
              src="~@/assets/svg/close.svg"
              alt=""
            />
          </div>
          <div class="c-modal__body">
            <slot name="body"> default body </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModelInfo",
  props: {
    max_width: undefined,
  },
};
</script>

<style lang="scss">
.c-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  overflow-y: scroll;

  &__wrapper {
    position: relative;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  &__container {
    max-width: 512px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 10px;
    @media screen and (min-width: 992px) {
      padding: 20px 30px;
    }
  }

  &__container-inner {
    background-color: #fff;
    color: #444;
    text-align: left;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }

  &__header {
    position: relative;
    display: flex;
    padding: 0px;
    position: relative;
    border: 0px solid #fff;
    padding: 1em;
  }

  &__header-text {
    margin: 0;
    color: #444;
    text-align: left;
    width: calc(100% - 30px);
    display: flex;
    span {
      margin: 0 auto;
    }
  }

  &__close-icon {
    width: 1em;
    right: 1em;
    top: 5px;
    right: 5px;
    position: absolute;
    padding: 1em;
    cursor: pointer;
    font-size: 1em;
  }

  &__body {
    margin: 0;
    border: 0px solid #fff;
    padding: 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }

  &__button-wrap {
    margin-top: 1em;
    display: column;
  }

  &__button {
    width: 100%;
    background: #ddd;
    border: 0px solid #fff;
    padding: 0.7em 0.3em;
    cursor: pointer;
  }

  &__button--confirm {
    margin-top: 0.5em;
    background: var(--date-picker-color-button, #2a5ea3);
    color: #fff;
  }
}

// vue fade

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// date picker styles

.mx-datepicker-main {
  border: 0px solid #fff;
}

.mx-calendar {
  padding: 0px 0px;
}

.mx-time {
  border: 1px solid #ddd;
  width: 246px;
}

.mx-table-date td,
.mx-table-date th {
  font-size: 15px;
}

.mx-time-column .mx-time-item {
  font-size: 15px;
}

.mx-time-column .mx-time-item.active {
  color: #fff;
  background-color: var(--date-picker-color-date-time, #2a5ea3);
  font-weight: 700;
}

.mx-calendar-content .cell.active {
  color: #fff;
  background-color: var(--date-picker-color-date-time, #2a5ea3);
}

.mx-table-date .today {
  color: var(--date-picker-color-date-time, #2a5ea3);
}

.mx-btn-icon-double-left,
.mx-btn-icon-double-right {
  display: none;
}

.mx-icon-left:before,
.mx-icon-right:before,
.mx-icon-double-left:before,
.mx-icon-double-right:before,
.mx-icon-double-left:after,
.mx-icon-double-right:after {
  width: 15px;
  height: 15px;
}

.mx-calendar-header-label {
  font-size: 1em;
  .mx-btn-text {
    font-size: 1em;
  }
}

.mx-time-list[data-type="minute"],
.mx-time-list[data-type="ampm"] {
  margin-right: 17px;
}

.mx-time-list[data-type="minute"]::after,
.mx-time-list[data-type="ampm"]::after {
  height: 0 !important;
}

.cell.highlight {
  background-color: var(--widget_date_picker_color_date_time_range, #2a5ea340);
}

.cell.highlight-startend {
  color: #fff;
  background-color: var(--widget_date_picker_color_date_time, #2a5ea3);
}

.mx-calendar-content .cell:hover {
  outline: 2px solid var(--widget_date_picker_color_date_time_range, #00549f40);
}

.mx-icon-left.mx-icon-left-custom:before {
  border-color: #000;
  border-width: 6px 0 0 6px;
  width: 20px;
  height: 20px;
}

.chev {
  cursor: pointer;
  padding: 1rem;
  margin: -1rem 0 0 -1rem;
}

.chev.chev-hide {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.mx-scrollbar-track {
  opacity: 1;
}
.mx-scrollbar-track .mx-scrollbar-thumb {
  background-color: #000000a1;
}
</style>
