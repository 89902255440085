<template>
  <div id="app" :class="[widthClass, widthLoaded]">
    <SearchWidget
      :propsData="propsData"
      :activeTab="activeTab"
      :brandData="brandData"
      :tabsData="tabsData"
      :widget_config="widget_config"
      :venue_data="venue_data"
      :keyUpdate="keyUpdate"
      :translations="translations"
      :brandSlug="brandSlug"
      :widthClass="widthClass"
      :fasttrack_rules="fasttrack_rules"
      :fasttrack_venues="fasttrack_venues"
      :lounge_rules="lounge_rules"
      :lounge_venues="lounge_venues"
      :use12h="use12h"
      :emitSearch="emitSearch"
      :loadingData="loadingData"
      :init_data="init_data"
      :products_arr="products_arr"
    />

    <!-- <pre style="text-align: left; font-size: 12px;">propsData</pre>
    <pre style="text-align: left; font-size: 12px;">{{propsData}}</pre>
    <pre style="text-align: left; font-size: 12px;">brandData</pre>
    <pre style="text-align: left; font-size: 12px;">{{brandData}}</pre> -->
  </div>
</template>

<script>
const axios = require("axios");
const genkeys = require("rezauthlib");
import SearchWidget from "./components/SearchWidget.vue";   

// <v-search
//   show-tabs="lounge parking fasttrack"
//   active-tab="lounge"
//   theme="dark light">
// </v-search>

export default {
  name: "App",
  components: {
    SearchWidget,
  },
  props: {
    showTabs: String,
    activeTab: String,
    brandTheme: String,
    brandSlug: String,
    hideTabs: String,
    dateStart: String,
    timeStart: String,
    dateEnd: String,
    timeEnd: String,
    buttonText: String,
    customClass: String,
    customLang: String,
    venueSelect: String,
    hideVenueSelect: String,
    laneSelect: String,
    adultsSelect: String,
    childrenSelect: String,
    infantsSelect: String,
    seniorSelect: String,
    use12h: String,
    emitSearch: String,
    loadingData: String,
  },
  data: () => ({
    propsData: {},
    brandData: {},
    widget_config: undefined,
    tabsData: [],
    containerWidth: 0,
    widthClass: "LG",
    widthLoaded: "width-not-loaded",
    widget_el: undefined,
    venue_data: [],
    keyUpdate: 0,
    translations: {},
    fasttrack_rules: [],
    fasttrack_venues: [],
    lounge_rules: [],
    lounge_venues: [],
    init_data: {},
    products_arr: [],
  }),
  methods: {
    get_brand_data() {
      let vm = this;
      let secsa = genkeys(
        process.env.VUE_APP_BRD_SECRETID,
        process.env.VUE_APP_BRD_SECRETKEY
      );
      let b1s = secsa.b1s;
      let v2s = secsa.v2s;
      let a1 = secsa.a1;
      let v1 = secsa.v1;
      let c2s = secsa.c2s;
      let uidgen = secsa.uidgen;

      let secsaa = genkeys(
        process.env.VUE_APP_INIT_SECRETID,
        process.env.VUE_APP_INIT_SECRETKEY
      );
      let b1sa = secsaa.b1s;
      let v2sa = secsaa.v2s;
      let a1a = secsaa.a1;
      let v1a = secsaa.v1;
      let c2sa = secsaa.c2s;
      let uidgena = secsaa.uidgen;
      axios
        .post(
          `${process.env.VUE_APP_BRANDAPI_URL}?brandslug=${this.brandSlug}`,
          "",
          {
            headers: {
              b1: b1s,
              v2: v2s,
              c2s: c2s,
              a1: a1,
              v1: v1,
              c1: uidgen,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let response_data = JSON.parse(response.request.response);

          /* eslint-disable */

          if (response_data.brand_widget_settings) {
            for (const [key, value] of Object.entries(
              response_data.brand_widget_settings
            )) {
              // only over ride this on staging or dev enviromnent
              if (process.env.VUE_APP_ENVIROMENT_TYPE === "staging") {
                if (
                  value.tab_label === "Lounge" &&
                  process.env.VUE_APP_OVERRIDE_LOUNGE_TAB === "true"
                ) {
                  value.active = "Y";
                }
                if (
                  value.tab_label === "Fasttrack" &&
                  process.env.VUE_APP_OVERRIDE_FASTTRACK_TAB === "true"
                ) {
                  value.active = "Y";
                }
              }

              this.tabsData.push(value);
              //console.log(this.tabsData);
            }
            /* eslint-enable */
            this.tabsData = this.tabsData.sort(function (a, b) {
              return new Number(a.tab_order) - new Number(b.tab_order);
            });
          }
          this.brandData = response_data;

          this.products_arr = response_data.widget_products || ["CPK"];
          if (!response_data.widget_products) {
            console.log('%c No widget_products array found, defaulting to "CPK"', 'background: #0000FF44; color: #FFF');
          }
          if (this.products_arr) {
            if (this.products_arr.indexOf("CPK") > -1) {
              req_parking();
            }
            if (this.products_arr.indexOf("LNG") > -1) {
              req_lounge();
            }
            if (this.products_arr.indexOf("FTT") > -1) {
              req_fasttrack();
            }
          }
          this.keyUpdate++;
        });
      let data = { brand_id: this.brandSlug };
      let req_parking = () => {
        axios
          .post(process.env.VUE_APP_CPK_URL, data, {
            headers: {
              b1: b1sa,
              v2: v2sa,
              c2s: c2sa,
              a1: a1a,
              v1: v1a,
              c1: uidgena,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            let response_data = JSON.parse(response.request.response);
            if (response) {
              vm.venue_data = response.data.data.venue;
            }

            /* eslint-disable */
            if (response_data && response_data.data) {
              vm.widget_config = response_data.data.widget_config;
              this.init_data["parking"] = response.data.data;
            }
            this.keyUpdate++;
          });
      };

      //get fasttrack data
      let req_fasttrack = () => {
        let secsab = genkeys(
          process.env.VUE_APP_FASTTRACK_INIT_SECRETID,
          process.env.VUE_APP_FASTTRACK_INIT_SECRETKEY
        );
        let b1ss = secsab.b1s;
        let v2ss = secsab.v2s;
        let a1s = secsab.a1;
        let v1s = secsab.v1;
        let c2ss = secsab.c2s;
        let uidgens = secsab.uidgen;
        let dataa = { brand_id: this.brandSlug };
        //console.log('process.env.VUE_APP_FASTTRACK_WRAPPER_URL', process.env.VUE_APP_FASTTRACK_WRAPPER_URL);
        axios
          .post(process.env.VUE_APP_FASTTRACK_WRAPPER_URL, dataa, {
            headers: {
              b1: b1ss,
              v2: v2ss,
              c2s: c2ss,
              a1: a1s,
              v1: v1s,
              c1: uidgens,
            },
          })
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.widget_config &&
              response.data.data.widget_config.lane_rules
            ) {
              this.fasttrack_rules =
                response.data.data.widget_config.lane_rules;
              this.fasttrack_venues = response.data.data.venue;
              this.init_data["fasttrack"] = response.data.data;
            }
            this.keyUpdate++;
          });
      };

      // get lounge data
      let req_lounge = () => {
        let secsac = genkeys(
          process.env.VUE_APP_LOUNGE_INIT_SECRETID,
          process.env.VUE_APP_LOUNGE_INIT_SECRETKEY
        );
        let b1sss = secsac.b1s;
        let v2sss = secsac.v2s;
        let a1ss = secsac.a1;
        let v1ss = secsac.v1;
        let c2sss = secsac.c2s;
        let uidgenss = secsac.uidgen;
        let dataaa = { brand_id: this.brandSlug };
        axios
          .post(process.env.VUE_APP_LOUNGE_URL, dataaa, {
            headers: {
              b1: b1sss,
              v2: v2sss,
              c2s: c2sss,
              a1: a1ss,
              v1: v1ss,
              c1: uidgenss,
            },
          })
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.widget_config &&
              response.data.data.widget_config.rules
            ) {
              this.lounge_rules = response.data.data.widget_config.rules;
              this.lounge_venues = response.data.data.venue;
              this.init_data["lounge"] = response.data.data;
            }
            this.keyUpdate++;
          });
      };
    },

    loadTranslations() {
      let vm = this;
      axios
        .get(
          "https://devhub.rezcomm.com/hubapmydbauth/brandapi/parkingtrans.php?brandslug=" +
            vm.brandSlug
        )
        .then((response) => {
          vm.translations = JSON.parse(response.request.response);
          vm.keyUpdate++;
        });
    },

    resize() {
      let widget_el_width = this.widget_el.offsetWidth;
      if (widget_el_width > 0) {
        this.widthLoaded = "";
      } else {
        this.widthLoaded = "width-not-loaded";
      }

      if (this.containerWidth !== widget_el_width) {
        this.containerWidth = widget_el_width;
      }

      if (this.containerWidth >= 525 && this.containerWidth < 1080) {
        this.widthClass = "MD";
      } else if (this.containerWidth < 525) {
        this.widthClass = "SM";
      } else {
        this.widthClass = "LG";
      }
    },
  },
  mounted() {
    this.widget_el = this.$el.querySelector("div").parentElement;
    let tabs = [];
    let arr_string = "";
    if (this.showTabs) {
      if (this.showTabs.indexOf(",")) {
        arr_string = this.showTabs;
        arr_string = arr_string.replace(/\s+/g, "");
        tabs = arr_string.split(",");
        //tabs = tabs.forEach((tab) => {tab})
      } else {
        tabs.push(this.showTabs);
      }
    }

    let search_button_text = "";
    if (!this.buttonText) {
      search_button_text = "Search";
    } else {
      search_button_text = this.buttonText;
    }

    this.propsData = {
      showTabs: tabs,
      activeTab: this.activeTab,
      theme: this.brandTheme,
      brand: this.brandSlug,
      hideTabs: this.hideTabs,
      dateStart: this.dateStart,
      timeStart: this.timeStart,
      dateEnd: this.dateEnd,
      timeEnd: this.timeEnd,
      buttonText: search_button_text,
      customClass: this.customClass,
      lang: this.customLang,
      venueSelect: this.venueSelect,
      hideVenueSelect: this.hideVenueSelect,
      laneSelect: this.laneSelect,
      adultsSelect: this.adultsSelect,
      childrenSelect: this.childrenSelect,
      infantsSelect: this.infantsSelect,
      seniorSelect: this.seniorSelect,
    };

    if (this.brandSlug) {
      this.loadTranslations();
      this.get_brand_data();
    }

    let vm = this;
    window.addEventListener(
      "resize",
      function (event) {
        vm.resize();
      },
      true
    );
    setInterval(() => {
      vm.resize();
    }, 500);
  },
  updated() {},
};
</script>

<style>
#app {
  transition: 0.2s ease-in-out;
}
.width-not-loaded {
  opacity: 0;
}
</style>
