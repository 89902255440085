<template>
  <!-- propsData.theme -->

  <div
    :class="`${
      propsData.customClass ? propsData.customClass : 'no-custom-class'
    }`"
  >
    <div
      :class="`c-widget${
        propsData.theme ? ' c-widget--' + propsData.theme : ''
      }`"
      :style="set_style_variables"
    >
      <!-- tabs -->
      <div
        :class="
          propsData.hideTabs && propsData.hideTabs === 'true'
            ? 'c-widget__all-tabs--hide'
            : ''
        "
        class="c-widget__all-tabs"
      >
        <template v-for="(tab, i) in tabsData">
          <template v-if="tab.active === 'Y'">
            <!-- if all prop passed -->
            <div
              v-if="
                tab.active &&
                propsData.showTabs.indexOf('all') > -1 &&
                user_selected_tab === strip(tab.tab_label)
              "
              :key="'tab-' + i"
              class="c-widget__tab c-widget__tab--active c-widget__tab--mod"
            >
              {{ getrans(tab.tab_label_display).toUpperCase() }}
            </div>
            <div
              v-if="
                tab.active &&
                propsData.showTabs.indexOf('all') > -1 &&
                user_selected_tab !== strip(tab.tab_label)
              "
              :key="'tab-' + i"
              class="c-widget__tab c-widget__tab--inactive c-widget__tab--mod"
              @click="changeTabUpdate(tab)"
            >
              {{ getrans(tab.tab_label_display).toUpperCase() }}
            </div>

            <!-- if array of params passed -->
            <div
              v-if="
                tab.active &&
                propsData.showTabs.indexOf(strip(tab.tab_label)) > -1 &&
                user_selected_tab === strip(tab.tab_label)
              "
              :key="'tab-' + i"
              class="c-widget__tab c-widget__tab--active c-widget__tab--mod"
            >
              {{ getrans(tab.tab_label_display).toUpperCase() }}
            </div>
            <div
              v-if="
                tab.active &&
                propsData.showTabs.indexOf(strip(tab.tab_label)) > -1 &&
                user_selected_tab !== strip(tab.tab_label)
              "
              :key="'tab-' + i"
              class="c-widget__tab c-widget__tab--inactive c-widget__tab--mod"
              @click="changeTabUpdate(tab)"
            >
              {{ getrans(tab.tab_label_display).toUpperCase() }}
            </div>
          </template>
        </template>
      </div>

      <!-- inputs -->
      <form @submit.prevent="goto_results">
        <div
          :class="
            propsData.hideTabs && propsData.hideTabs === 'true'
              ? 'c-widget__all-tabs--hide-mobile'
              : ''
          "
          class="c-widget__inputs c-widget__inputs--mod"
        >
          <template v-for="(tab, i) in tabsData">
            <template v-if="tab.active === 'Y'">
              <div
                :key="'tab-container-' + i"
                :class="[
                  tab.active &&
                  propsData.showTabs.indexOf(strip(tab.tab_label)) > -1 &&
                  user_selected_tab === strip(tab.tab_label)
                    ? 'active'
                    : '',
                ]"
                :data-title="[
                  tab.active &&
                  propsData.showTabs.indexOf(strip(tab.tab_label)) > -1
                    ? getrans(tab.tab_label_display)
                    : '',
                ]"
                class="c-widget__row"
                @click="show_tab($event, tab)"
              >
                <div class="c-widget__row-content">
                  <!-- venue select -->
                  <label
                    v-if="tab_select_venue && hide_venue_select !== 'true'"
                    class="c-widget__label c-widget__label-full-on-MD"
                    for="venue_select"
                    ><span class="c-widget__label-span">{{
                      getrans(tab.venue_label)
                    }}</span>
                    <select
                      id="venue_select"
                      v-model="venue_select"
                      class="c-widget__select"
                      type="text"
                    >
                      <option disabled value="0">
                        {{ getrans(tab.venue_label) }}
                      </option>
                      <template v-if="tab.tab_label === 'Parking'">
                          <option
                            v-for="venue in venue_data"
                            :value="venue.venue_id"
                            :key="venue.venue_id"
                            :ref="`venue_select_name-${tab.tab_label}-${venue.venue_id}`"
                            :data-venue-name="venue.venue_name"
                          >
                            {{ getrans(venue.venue_name) }}
                          </option>
                        </template>
                        <template v-if="tab.tab_label === 'Fasttrack'">
                          <option
                            v-for="venue in fasttrack_venues"
                            :value="venue.venue_id"
                            :key="venue.venue_id"
                            :ref="`venue_select_name-${tab.tab_label}-${venue.venue_id}`"
                            :data-venue-name="venue.name"
                          >
                            {{ getrans(venue.name) }}
                          </option>
                        </template>
                        <template v-if="tab.tab_label === 'Lounge'">
                          <option
                            v-for="venue in lounge_venues"
                            :value="venue.venue_id"
                            :key="venue.venue_id"
                            :ref="`venue_select_name-${tab.tab_label}-${venue.venue_id}`"
                            :data-venue-name="venue.venue_name"
                          >
                            {{ getrans(venue.venue_name) }}
                          </option>
                        </template>
                    </select>
                    <img
                      alt="search icon"
                      class="c-widget__search-icon"
                      src="~@/assets/svg/chev-her.svg"
                    />
                  </label>

                  <!-- Select Lane -->
                  <label
                      class="c-widget__label custom-select"
                      for="lane_select"
                      :class="[lane_select_error ? 'c-widget--error' : '']"
                      v-if="
                        tab.tab_label === 'Fasttrack' && lane_options.length > 1
                      "
                      ><span>{{ getrans("Select Lane") }}</span>
                      <select
                        class="c-widget__select"
                        id="lane_select"
                        ref="lane_select"
                        v-model="lane_selected"
                      >
                        <option value="0" disabled>
                          {{ getrans(tab.lane_label || "Select Lane") }}
                        </option>
                        <option
                          v-for="lane in lane_options"
                          :key="lane.lane_id"
                          :value="lane.lane_id"
                          :ref="`lane_select_name-${lane.lane_id}`"
                          :data-lane-name="lane.name"
                          :style="`${
                            lane.visibility === 'onsite' ? 'display: none;' : ''
                          }`"
                        >
                          {{ getrans(lane.name) }}
                        </option>
                      </select>
                      <svg
                        class="c-widget__search-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        id="selection-prev"
                        viewBox="0 0 896 662"
                        width="20"
                        height="20"
                      >
                        <path
                          d="M 105.00,78.44
      C 122.71,76.17 140.64,79.17 155.91,88.57
        162.22,92.45 172.61,102.61 178.00,108.00
        178.00,108.00 205.00,135.00 205.00,135.00
        205.00,135.00 382.00,312.00 382.00,312.00
        382.00,312.00 428.00,358.00 428.00,358.00
        428.00,358.00 440.00,370.00 440.00,370.00
        441.59,371.58 444.88,375.13 447.00,375.66
        449.83,376.37 452.16,373.73 454.00,371.98
        454.00,371.98 469.00,357.00 469.00,357.00
        469.00,357.00 533.00,293.00 533.00,293.00
        533.00,293.00 689.00,137.00 689.00,137.00
        689.00,137.00 718.00,108.00 718.00,108.00
        727.94,98.06 737.78,88.27 751.00,82.82
        762.84,77.93 772.55,77.86 785.00,78.00
        815.75,78.36 836.08,103.08 856.00,123.00
        866.35,133.35 878.42,144.12 885.54,157.00
        889.03,163.32 892.78,171.81 894.00,179.00
        894.00,179.00 894.00,185.00 894.00,185.00
        894.74,188.80 896.24,190.80 895.78,195.00
        895.78,195.00 894.00,202.00 894.00,202.00
        894.00,202.00 894.00,208.00 894.00,208.00
        892.73,215.53 888.35,225.56 884.34,232.00
        878.01,242.16 861.99,257.01 853.00,266.00
        853.00,266.00 807.00,312.00 807.00,312.00
        807.00,312.00 565.00,554.00 565.00,554.00
        565.00,554.00 521.00,598.00 521.00,598.00
        508.26,610.74 494.53,626.89 478.00,634.29
        465.76,639.77 456.03,640.15 443.00,640.00
        415.14,639.67 399.26,622.26 381.00,604.00
        381.00,604.00 311.00,534.00 311.00,534.00
        311.00,534.00 73.00,296.00 73.00,296.00
        73.00,296.00 49.00,272.00 49.00,272.00
        36.68,259.68 15.24,240.22 7.82,225.00
        6.08,221.43 2.75,211.83 2.00,208.00
        2.00,208.00 2.00,202.00 2.00,202.00
        1.26,198.20 -0.24,196.20 0.22,192.00
        0.22,192.00 1.79,185.00 1.79,185.00
        1.79,185.00 2.37,178.00 2.37,178.00
        3.91,169.88 7.83,161.00 12.17,154.00
        16.81,146.51 39.70,123.30 47.00,116.00
        66.19,96.81 77.12,83.66 105.00,78.44 Z"
                        />
                      </svg>
                    </label>

                  <!-- start date input -->
                  <label
                    :class="[date1_date_error ? 'c-widget--error' : '']"
                    class="c-widget__label"
                    for="date1_date"
                    ><span class="c-widget__label-span">{{
                      getrans(tab.startdate_label)
                    }}</span>
                    <input
                      id="date1_date"
                      v-model="date1_date"
                      :placeholder="getrans(tab.startdate_placeholder)"
                      class="c-widget__input"
                      readonly
                      type="text"
                      @click="
                        show_times = false;
                        show_dates = true;
                        showModal_datepicker = true;
                        set_date_if_no_date();
                      "
                    />
                    <img
                      alt="search icon"
                      class="c-widget__search-icon"
                      src="~@/assets/svg/chev-her.svg"
                    />
                  </label>

                  <!-- start time input -->
                  <label
                    :class="[date1_time_error ? 'c-widget--error' : '']"
                    class="c-widget__label c-widget__label--sm-time"
                    for="date1_time"
                    ><span class="c-widget__label-span">
                      {{ getrans(tab.starttime_label) }}
                    </span>
                    <input
                      id="date1_time"
                      v-model="date1_time"
                      :placeholder="getrans(tab.starttime_placeholder)"
                      class="c-widget__input"
                      readonly
                      type="text"
                      @click="
                        show_dates = false;
                        show_times = true;
                        showModal_datepicker = true;
                        set_date_if_no_date();
                      "
                    />
                    <img
                      alt="search icon"
                      class="c-widget__search-icon"
                      src="~@/assets/svg/chev-her.svg"
                    />
                  </label>

                  <!-- end date input -->
                  <label
                    v-if="tab_show_enddate"
                    :class="[date2_date_error ? 'c-widget--error' : '']"
                    class="c-widget__label"
                    for="date2_date"
                    ><span class="c-widget__label-span">{{
                      getrans(tab.enddate_label)
                    }}</span>
                    <input
                      id="date2_date"
                      v-model="date2_date"
                      :placeholder="getrans(tab.enddate_placeholder)"
                      class="c-widget__input"
                      readonly
                      type="text"
                      @click="
                        show_times = false;
                        show_dates = true;
                        showModal_datepicker_end = true;
                        set_date_if_no_date_end();
                      "
                    />
                    <img
                      alt="search icon"
                      class="c-widget__search-icon"
                      src="~@/assets/svg/chev-her.svg"
                    />
                  </label>

                  <!-- end time input -->
                  <label
                    v-if="tab_show_enddate"
                    :class="[date2_time_error ? 'c-widget--error' : '']"
                    class="c-widget__label c-widget__label--sm-time"
                    for="date2_time"
                    ><span class="c-widget__label-span">
                      {{ getrans(tab.endtime_label) }}
                    </span>
                    <input
                      id="date2_time"
                      v-model="date2_time"
                      :placeholder="getrans(tab.endtime_placeholder)"
                      class="c-widget__input"
                      readonly
                      type="text"
                      @click="
                        show_dates = false;
                        show_times = true;
                        showModal_datepicker_end = true;
                        set_date_if_no_date_end();
                      "
                    />
                    <img
                      alt="search icon"
                      class="c-widget__search-icon"
                      src="~@/assets/svg/chev-her.svg"
                    />
                  </label>

                  <div class="adult-child-infant-senior">
                    <!-- adults select -->
                    <label
                      v-if="tab.adults_label"
                      class="c-widget__label c-widget__label--sm"
                      for="venue_select"
                      style="max-width: calc(33% - 15px) !important"
                      ><span class="c-widget__label-span">{{
                        getrans(tab.adults_label)
                      }}</span>
                      <select
                        id="venue_select"
                        v-model="adults_select"
                        class="c-widget__select"
                        type="text"
                        @change="validate_data"
                      >
                        <option
                          v-for="n in adult_count"
                          :key="'ADT-' + n"
                          :value="n"
                        >
                          {{ n }}
                        </option>
                      </select>
                      <img
                        alt="search icon"
                        class="c-widget__search-icon"
                        src="~@/assets/svg/chev-her.svg"
                      />
                    </label>

                    <!-- children select -->
                    <label
                      v-if="tab.children_label && tab.children_active === 'Y'"
                      class="c-widget__label c-widget__label--sm"
                      for="venue_children"
                      style="max-width: calc(33% - 15px) !important"
                      ><span class="c-widget__label-span">{{
                        getrans(tab.children_label)
                      }}</span>
                      <select
                        id="venue_children"
                        v-model="children_select"
                        class="c-widget__select"
                        type="text"
                        @change="validate_data"
                      >
                        <option value="0">0</option>
                        <option
                          v-for="n in children_count"
                          :key="'CHD-' + n"
                          :value="n"
                        >
                          {{ n }}
                        </option>
                      </select>
                      <img
                        alt="search icon"
                        class="c-widget__search-icon"
                        src="~@/assets/svg/chev-her.svg"
                      />
                    </label>

                    <!-- infants select -->
                    <label
                      v-if="tab.infants_label && tab.infants_active === 'Y'"
                      class="c-widget__label c-widget__label--sm"
                      for="venue_infants"
                      style="max-width: calc(33% - 15px) !important"
                      ><span class="c-widget__label-span">{{
                        getrans(tab.infants_label)
                      }}</span>
                      <select
                        id="venue_infants"
                        v-model="infants_select"
                        class="c-widget__select"
                        type="text"
                        @change="validate_data"
                      >
                        <option value="0">0</option>
                        <option
                          v-for="n in infant_count"
                          :key="'INF-' + n"
                          :value="n"
                        >
                          {{ n }}
                        </option>
                      </select>
                      <img
                        alt="search icon"
                        class="c-widget__search-icon"
                        src="~@/assets/svg/chev-her.svg"
                      />
                    </label>

                    <!-- senior select -->
                    <label
                      v-if="tab.senior_label && tab.senior_active === 'Y'"
                      class="c-widget__label c-widget__label--sm"
                      for="senior_select"
                      ><span class="c-widget__label-span">{{
                        getrans(tab.senior_label)
                      }}</span>
                      <select
                        id="senior_select"
                        v-model="senior_select"
                        class="c-widget__select"
                        type="text"
                      >
                        <option value="0">0</option>
                        <option v-for="n in 10" :key="'SNR-' + n" :value="n">
                          {{ n }}
                        </option>
                      </select>
                      <img
                        alt="search icon"
                        class="c-widget__search-icon"
                        src="~@/assets/svg/chev-her.svg"
                      />
                    </label>
                  </div>

                  <button class="c-widget__button" type="submit">
                    <!-- <img
                      class="c-widget__search-icon"
                      src="~@/assets/svg/desktop/Search.svg"
                      alt="search icon"
                    /> -->

                    <!-- <div class="loading-icon" v-if="loading_icon">
                      <svg
                        class="rotating"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.25rem"
                        height="1.25rem"
                        viewBox="0 0 100 100"
                      >
                        <path
                          id="Selection"
                          stroke="none"
                          stroke-width="0"
                          d="M 38.00,1.00
           C 38.00,1.00 39.00,12.00 39.00,12.00
             33.14,13.60 28.53,15.99 24.00,20.10
             16.65,26.79 11.25,34.92 10.17,45.00
             7.67,68.33 27.13,90.90 51.00,89.96
             84.90,88.63 105.77,44.74 75.00,19.33
             70.92,15.95 66.08,13.45 61.00,12.00
             61.00,12.00 61.00,1.00 61.00,1.00
             76.92,5.75 88.40,13.44 95.68,29.00
             110.33,60.33 88.57,97.50 54.00,99.91
             16.61,102.53 -10.63,65.86 3.45,31.00
             10.47,13.63 21.48,7.19 38.00,1.00 Z"
                        />
                      </svg>
                    </div> -->

                    {{ getrans(propsData.buttonText) }}
                    <!-- <span>Find a Space</span> -->
                  </button>
                </div>
              </div>
            </template>
          </template>
        </div>
      </form>
      <!-- <div v-for="(tab, i) in tabsData" :key="'displat-data-' + i">
        <pre style="text-align: left; font-size: 12px; user-select: text">
          tab_label: {{ tab.tab_label }}
          active: {{ tab.active }}
          startdate_label: {{ tab.startdate_label }}
          starttime_label: {{ tab.starttime_label }}
          enddate_label: {{ tab.enddate_label }}
          endtime_label: {{ tab.endtime_label }}
          venue_label: {{ tab.venue_label }}
          venue_active: {{ tab.venue_active }}
          adults_label: {{ tab.adults_label }}
          children_label: {{ tab.children_label }}
          children_active: {{ tab.children_active }}
          infants_label: {{ tab.infants_label }}
          infants_active: {{ tab.infants_active }}
          senior_label: {{ tab.senior_label }}
          senior_active: {{ tab.senior_active }}

          parking_startdate_placeholder: {{ tab.startdate_placeholder }}
          parking_starttime_placeholder: {{ tab.starttime_placeholder }}
          parking_enddate_placeholder: {{ tab.enddate_placeholder }}
          parking_endtime_placeholder: {{ tab.endtime_placeholder }}

        </pre>
      </div> -->
    </div>

    <!-- dtp popup -->
    <transition name="fade">
      <Modal
        max_width="290"
        v-if="showModal_datepicker"
        @close="showModal_datepicker = false"
      >
        <template v-slot:header>
          <i
            class="mx-icon-left mx-icon-left-custom"
            :class="show_times && date1 ? 'chev' : 'chev chev-hide'"
            @click="
              show_dates = true;
              show_times = false;
            "
          ></i>
          <span>{{
            show_dates
              ? getrans(current_tab.select_start_date)
              : getrans(current_tab.select_start_time)
          }}</span>
        </template>

        <template v-slot:body>
          <div class="c-pickers">
            <date-picker
              :show-time-panel="show_times"
              inline
              v-model="date1"
              value-type="date"
              type="datetime"
              :disabled-date="disabled_before_today_and_after"
              :disabled-time="not_before_now_plus_hours"
              :minute-step="minute_step"
              :show-second="false"
              :show-time-header="false"
              :calendar-change="calendar_change()"
              :time-title-format="(brandSlug == 'RDU') ? `MMMM DD YY` : `DD MMMM YY`"
              :use12h="use12h === 'true' ? true : false"
              :get-classes="getClasses"
              @pick="handlePick"
            >
            </date-picker>
            <div class="c-modal__button-wrap" v-if="show_dates && date1">
              <button
                class="c-modal__button c-modal__button--confirm"
                @click="
                  show_dates = false;
                  show_times = true;
                "
              >
                {{ getrans("Confirm") }}
              </button>
            </div>

            <div class="c-modal__button-wrap" v-if="show_times && date1">
              <!-- <button
                class="c-modal__button"
                @click="
                  show_dates = true;
                  show_times = false;
                "
              >
                {{ getrans("Back To Date") }}
              </button> -->
              <button
                class="c-modal__button c-modal__button--confirm"
                v-if="show_times"
                @click="
                  showModal_datepicker = false;
                  confirm_times(1);
                "
              >
                {{ getrans("Confirm") }}
              </button>
            </div>
          </div>
        </template>
      </Modal>
    </transition>

    <!-- dtp popup -->
    <transition name="fade">
      <Modal
        max_width="290"
        v-if="showModal_datepicker_end"
        @close="showModal_datepicker_end = false"
      >
        <template v-slot:header>
          <i
            class="mx-icon-left mx-icon-left-custom"
            :class="show_times && date2 ? 'chev' : 'chev chev-hide'"
            @click="
              show_dates = true;
              show_times = false;
            "
          ></i>
          <span>{{
            show_dates
              ? getrans(current_tab.select_end_date)
              : getrans(current_tab.select_end_time)
          }}</span>
        </template>
        <template v-slot:body>
          <div class="c-pickers">
            <date-picker
              :show-time-panel="show_times"
              inline
              v-model="date2"
              value-type="date"
              type="datetime"
              :disabled-date="disabled_before_today_and_after_end"
              :disabled-time="not_before_now_plus_hours_end"
              :minute-step="minute_step"
              :show-second="false"
              :show-time-header="false"
              :calendar-change="calendar_change()"
              :time-title-format="(brandSlug == 'RDU') ? `MMMM DD YY` : `DD MMMM YY`"
              :use12h="use12h === 'true' ? true : false"
              :get-classes="getClasses"
              @pick="handlePick"
            >
            </date-picker>
            <div class="c-modal__button-wrap" v-if="show_dates && date2">
              <button
                class="c-modal__button c-modal__button--confirm"
                @click="
                  show_dates = false;
                  show_times = true;
                "
              >
                {{ getrans("Confirm") }}
              </button>
            </div>

            <div class="c-modal__button-wrap" v-if="show_times && date2">
              <!-- <button
                class="c-modal__button"
                @click="
                  show_dates = true;
                  show_times = false;
                "
              >
                {{ getrans("Back To Date") }}
              </button> -->
              <button
                class="c-modal__button c-modal__button--confirm"
                v-if="show_times"
                @click="
                  showModal_datepicker_end = false;
                  confirm_times(2);
                "
              >
                {{ getrans("Confirm") }}
              </button>
            </div>
          </div>
        </template>
      </Modal>
    </transition>

    <!-- error popup -->
    <transition name="fade">
      <Modal
        v-if="showModal_error"
        max_width="290"
        @close="showModal_error = false"
      >
        <template v-slot:header> {{ getrans("Missing fields") }}</template>

        <template v-slot:body>
          {{
            getrans(
              "To continue with your search please fill in the missing fields."
            )
          }}
        </template>
      </Modal>
    </transition>

    <!-- error time popup -->
    <transition name="fade">
      <Modal
        v-if="showModal_mindur"
        max_width="290"
        @close="showModal_mindur = false"
      >
        <template v-slot:header> {{ getrans("Minimum Duration") }}</template>

        <template v-slot:body>
          {{
            getrans("Sorry the minimum duration for prebook parking is 1 hr.")
          }}
        </template>
      </Modal>
    </transition>
  </div>
</template>


<script>
import Modal from "./Modal.vue";
import DatePicker from "vue2-datepicker";
import md5 from "js-md5";

/* eslint-disable */
import en from "date-format-parse/lib/locale/en";
import el from "date-format-parse/lib/locale/el";
import de from "date-format-parse/lib/locale/de";
import fr from "date-format-parse/lib/locale/fr";
import es from "date-format-parse/lib/locale/es";
import pt from "date-format-parse/lib/locale/pt";
/* eslint-enable */

export default {
  name: "StartPage",
  components: { 
    Modal, 
    DatePicker, 
  },
  props: {
    propsData: Object,
    brandData: Object,
    widget_config: Object,
    tabsData: Array,
    activeTab: String,
    brandSlug: String,
    venue_data: Array,
    translations: Object,
    widthClass: String,
    fasttrack_rules: Array,
    fasttrack_venues: Array,
    lounge_rules: Array,
    lounge_venues: Array,
    use12h: String,
    emitSearch: String,
    loadingData: String,
    init_data: Object,
    products_arr: Array,
  },
  data: () => ({
    last_clicked_date_start: false,
    last_clicked_date_end: false,
    tab_show_enddate: false,
    tab_select_venue: false,
    tab_select_lane: false,
    tab_advance_booking_hours: 0,
    showModal_datepicker: false,
    showModal_datepicker_end: false,
    show_dates: false,
    show_times: false,
    hour_options: [],
    goto_domain: "",
    goto_page_url: "results.html",
    date1: null,
    date2: null,
    hide_venue_select: false,
    venue_select: "0",
    adults_select: "1",
    children_select: "0",
    infants_select: "0",
    senior_select: "0",
    minute_step: 15,
    showTimePanel: false,
    showTimeRangePanel: false,
    user_selected_tab: "",
    current_tab: "",
    date1_date: "",
    date1_time: "",
    date2_date: "",
    date2_time: "",
    date1_date_error: false,
    date1_time_error: false,
    date2_date_error: false,
    date2_time_error: false,
    venue_select_error: false,
    showModal_error: false,
    showModal_mindur: false,
    is_initialised: false,
    widget_data: {},
    last_allowed_date: "",
    lang: "en",
    months_arr: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    loading_icon: false,
    fasttrack_start_date: null,
    lounge_start_date: null,
    adult_count: 10,
    children_count: 10,
    infant_count: 10,

    // lanes
    lane_options: [],
    lane_selected: "0",
    lane_select_error: false,
    // styles
    brand_color_1: "",
    brand_color_2: "",
    brand_color_3: "",
    tab_active_text_color: "",
    tab_active_text_size: "",
    tab_inactive_text_color: "",
    tab_inactive_text_size: "",
    mobile_tab_active_background_color: "",
    mobile_tab_inactive_background_color: "",
    mobile_tab_active_text_color: "",
    mobile_tab_inactive_text_color: "",
    border_radius: "",
    // newer styles
    widget_panel_bg_color: "",
    widget_panel_border: "",
    widget_panel_radius: "",
    widget_panel_mobile_bg_color: "",
    widget_panel_mobile_border: "",
    widget_panel_mobile_radius: "",
    widget_tabs_bg_color: "",
    widget_tabs_bg_color_hvr: "",
    widget_tabs_text_color: "",
    widget_tabs_text_color_hvr: "",
    widget_tabs_active_bg_color: "",
    widget_tabs_active_bg_color_hvr: "",
    widget_tabs_active_text_color: "",
    widget_tabs_active_text_color_hvr: "",
    widget_tabs_font: "",
    widget_tabs_font_size: "",
    widget_tabs_border_radius: "",
    widget_tabs_mobile_bg_color: "",
    widget_tabs_mobile_bg_color_hvr: "",
    widget_tabs_mobile_text_color: "",
    widget_tabs_mobile_text_color_hvr: "",
    widget_tabs_mobile_active_bg_color: "",
    widget_tabs_mobile_active_bg_color_hvr: "",
    widget_tabs_mobile_active_text_color: "",
    widget_tabs_mobile_active_text_color_hvr: "",
    widget_tabs_mobile_font: "",
    widget_tabs_mobile_font_size: "",
    widget_inputs_bg_color: "",
    widget_inputs_text_color: "",
    widget_inputs_font: "",
    widget_inputs_font_size: "",
    widget_inputs_label_text_color: "",
    widget_inputs_label_font: "",
    widget_inputs_label_font_size: "",
    widget_inputs_mobile_bg_color: "",
    widget_inputs_mobile_text_color: "",
    widget_inputs_mobile_font: "",
    widget_inputs_mobile_font_size: "",
    widget_inputs_mobile_label_text_color: "",
    widget_inputs_mobile_label_font: "",
    widget_inputs_mobile_label_font_size: "",
    widget_search_button_font_family: "",
    widget_search_button_font_size: "",
    widget_search_button_font_weight: "",
    widget_search_button_font_color: "",
    widget_search_button_background: "",
    widget_search_button_border: "",
    widget_search_button_border_radius: "",
    widget_date_picker_color_date_time: "",
    widget_date_picker_color_button: "",
  }),
  computed: {
    slide_transition: function () {
      let transition = 0;
      if (this.widthClass === "SM") {
        transition = 300;
      }
      if (this.propsData.hideTabs && this.propsData.hideTabs === "true") {
        transition = 0;
      }
      return transition;
    },
    set_style_variables: function () {
      return {
        "--brand_color_1": this.brand_color_1 || "#ffffff",
        "--brand_color_2": this.brand_color_2 || "#1a2a40",
        "--brand_color_3": this.brand_color_3 || "#dea602",
        "--tab_active_text_color": this.tab_active_text_color || "#1a2a40",
        "--tab_active_text_size": this.tab_active_text_size || "0.9rem",
        "--tab_in_active_text_color": this.tab_inactive_text_color || "#1a2a40",
        "--tab_in_active_text_size": this.tab_inactive_text_size || "0.9rem",
        "--border_radius": this.border_radius || "0",
      };
    },
  },
  methods: {

    


    set_style(brand_styles) {
      if (!brand_styles) return;
      //console.log(brand_styles);

      this.brand_color_1 = this.get_key_value(brand_styles, "brand_color_1");
      this.brand_color_2 = this.get_key_value(brand_styles, "brand_color_2");
      this.brand_color_3 = this.get_key_value(brand_styles, "brand_color_3");
      this.tab_active_text_color = this.get_key_value(
        brand_styles,
        "panel_heading_text_color"
      );
      this.tab_inactive_text_color = this.get_key_value(
        brand_styles,
        "panel_heading_text_color"
      );
    },
    handlePick(date) {
      //console.log(date);
      if (this.showModal_datepicker_end) {
        this.last_clicked_date_end = new Date(date);
      } else {
        this.last_clicked_date_start = new Date(date);
      }
    },
    getClasses(date) {
      let celldate = new Date(date);
      if (
        this.date2 &&
        this.last_clicked_date_start &&
        this.last_clicked_date_end
      ) {
        if (this.showModal_datepicker_end) {
          if (
            this.last_clicked_date_start &&
            celldate.getFullYear() ===
              this.last_clicked_date_start.getFullYear() &&
            celldate.getMonth() === this.last_clicked_date_start.getMonth() &&
            celldate.getDate() === this.last_clicked_date_start.getDate()
          ) {
            return "highlight-startend";
          }
          if (
            this.last_clicked_date_start &&
            this.last_clicked_date_end &&
            celldate > this.last_clicked_date_start &&
            celldate < this.last_clicked_date_end
          ) {
            return "highlight";
          }
        } else {
          if (
            this.last_clicked_date_end &&
            celldate.getFullYear() ===
              this.last_clicked_date_end.getFullYear() &&
            celldate.getMonth() === this.last_clicked_date_end.getMonth() &&
            celldate.getDate() === this.last_clicked_date_end.getDate()
          ) {
            return "highlight-startend";
          }
          if (
            this.last_clicked_date_start &&
            this.last_clicked_date_end &&
            celldate > this.last_clicked_date_start &&
            celldate < this.last_clicked_date_end
          ) {
            return "highlight";
          }
        }
      }
      return "";
    },
    getrans(string) {
      if (string === undefined) return "";
      var s = md5(string);
      var ss = this.propsData.brand + "-" + this.lang.toUpperCase() + "-" + s;
      return this.translations[ss] || string;
    },
    loadLanguage(lang) {
      let language;
      let digits = 2;
      if (lang === "en") {
        return false;
      }
      if (lang === "el") {
        language = el;
      }
      if (lang === "de") {
        language = de;
      }
      if (lang === "fr") {
        language = fr;
      }
      if (lang === "es") {
        language = es;
        digits = 3;
      }
      if (lang === "pt") {
        language = pt;
        digits = 3;
      }
      let lang_obj;
      if (digits === 2) {
        lang_obj = {
          formatLocale: language,
          yearFormat: "YYYY",
          monthFormat: "MMM",
          monthBeforeYear: true,
        };
      } else if (digits === 3) {
        lang_obj = {
          formatLocale: language,
          yearFormat: "YYYY",
          monthFormat: "MMM",
          days: language.weekdaysShort,
          monthBeforeYear: true,
        };
      }
      this.months_arr = lang_obj.formatLocale.monthsShort;
      DatePicker.locale(lang, lang_obj);
    },

    changeOfVenue() {
      let tab = this.current_tab;
      this.date1 = "";
      this.date1_date = "";
      this.date1_time = "";
      this.date2 = "";
      this.date2_date = "";
      this.date2_time = "";
      this.adults_select = 1;
      this.children_select = 0;
      this.infants_select = 0;
      let current_tab_label = tab.tab_label.toLowerCase();
      this.last_clicked_date_start = false;
      this.last_clicked_date_end = false;
      this.lane_options = [];
      this.lane_selected = 0;
      this.lane_select_error = false;

      if (current_tab_label === "parking") {
        let venue_data = this.init_data[current_tab_label].venue;
        venue_data.map((venue) => {
          if (this.venue_select.toString() === venue.venue_id.toString()) {
            this.tab_advance_booking_hours = venue.advance_booking_hours || 0;
            this.minute_step = parseInt(venue.website_time_gap) || 15;
          }
        });
      }
      if (current_tab_label === "fasttrack") {
        let venue_data = this.init_data[current_tab_label].venue;
        venue_data.map((venue) => {
          if (this.venue_select.toString() === venue.venue_id.toString()) {
            this.tab_advance_booking_hours = venue.advance_booking_hours || 0;
            this.minute_step = parseInt(venue.website_time_gap) || 15;
          }
        });
        //console.log("venue_data", venue_data, this.venue_select.toString());
        let lane_data = this.init_data[current_tab_label].lane;
        if (lane_data) {
          lane_data.map((venue) => {
            if (this.venue_select.toString() === venue.venue_id.toString()) {
              this.lane_options.push(venue);
            }
          });
        }
      }
      if (current_tab_label === "lounge") {
        let venue_data = this.init_data[current_tab_label].venue;
        venue_data.map((venue) => {
          if (venue_data.length === 1) {
            this.venue_select = venue_data[0].venue_id || "0";
          }
          if (this.venue_select.toString() === venue.venue_id.toString()) {
            this.tab_advance_booking_hours = venue.advance_booking_hours || 0;
            this.minute_step = parseInt(venue.min_time_slot) || 15;
          }
        });
      }
    },

    changeTabUpdate(tab) {
      if (!tab) {
        return false;
      }
      let current_tab_label = tab.tab_label.toLowerCase();

      if (!this.init_data[current_tab_label]) {
        return false;
      }

      this.minute_step = 15;
      this.tab_advance_booking_hours = 0;
      this.lane_options = [];

      if (current_tab_label === "parking") {
        let widget_data = this.init_data[current_tab_label].widget_config;
        let venue_data = this.init_data[current_tab_label].venue;
        if (widget_data) {
          this.minute_step = widget_data.min_time_slot || 15;
          this.last_allowed_date = widget_data.end_date;
          this.tab_select_venue = widget_data.select_venue;
          //this.tab_select_venue = true;
          this.tab_show_enddate = widget_data.select_end_date || true;
        }
        if (venue_data) {
          let vn = venue_data[0];
          if (vn && vn.website_time_gap) {
            this.minute_step = parseInt(vn.website_time_gap);
          }
          if (vn && vn.advance_booking_hours) {
            this.tab_advance_booking_hours = vn.advance_booking_hours || 0;
          }
        }
      }

      if (current_tab_label === "fasttrack") {
        let widget_data = this.init_data[current_tab_label].widget_config;
        let venue_data = this.init_data[current_tab_label].venue;
        if (widget_data) {
          this.minute_step = widget_data.min_time_slot || 15;
          this.last_allowed_date = widget_data.end_date;
          this.tab_select_venue = widget_data.select_venue;
          this.tab_select_lane = widget_data.select_lane;
          this.tab_show_enddate = widget_data.select_end_date || false;
        }
        if (venue_data) {
          let vn = venue_data[0];
          if (vn && vn.website_time_gap) {
            this.minute_step = parseInt(vn.website_time_gap);
          }
          if (vn && vn.advance_booking_hours) {
            this.tab_advance_booking_hours = vn.advance_booking_hours || 0;
          }
        }

        let lane_data = this.init_data[current_tab_label].lane;
        if (lane_data) {
          lane_data.map((venue) => {
            if (this.fasttrack_venues.length > 1) {
              if (this.venue_select.toString() === venue.venue_id.toString()) {
                this.lane_options.push(venue);
              }
            } else if (this.fasttrack_venues.length === 1) {
              this.lane_options.push(venue);
            }
          });
        }
      }

      if (current_tab_label === "lounge") {
        let widget_data = this.init_data[current_tab_label].widget_config;
        let venue_data = this.init_data[current_tab_label].venue;
        if (widget_data) {
          this.minute_step = widget_data.min_time_slot || 15;
          this.last_allowed_date = widget_data.end_date;
          this.tab_select_venue = widget_data.select_venue;
          this.tab_show_enddate = widget_data.select_end_date || false;
        }
        if (venue_data) {
          this.lounge_venues = venue_data;
          let vn = venue_data[0];
          if (vn && vn.min_time_slot) {
            this.minute_step = parseInt(vn.min_time_slot);
          }
          if (vn && vn.advance_booking_hours) {
            this.tab_advance_booking_hours = vn.advance_booking_hours || 0;
          }
        }
      }

      this.user_selected_tab = this.strip(tab.tab_label);
      this.current_tab = tab;
      this.date1 = "";
      this.date1_date = "";
      this.date1_time = "";
      this.date2 = "";
      this.date2_date = "";
      this.date2_time = "";
      this.venue_select = "0";
      this.adults_select = 1;
      this.children_select = 0;
      this.infants_select = 0;
      this.last_clicked_date_start = this.date1;
      this.last_clicked_date_end = this.date2;
      this.lane_selected = 0;
      this.lane_select_error = false;

      this.reset_validate_data();
      const event = new CustomEvent("tabChanged", {
        detail: tab.hero_settings,
      });
      window.dispatchEvent(event);
    },
    confirm_times(d) {
      let new_date = new Date();
      if (d === 1 && this.date1.getTime() - new_date.getTime()) {
        this.date1_date = this.format_date(this.date1, "date");
        this.date1_time = this.format_date(this.date1, "time");
        //alert(this.date1.getTime()-new_date.getTime());
      }
      if (d === 2) {
        this.date2_date = this.format_date(this.date2, "date");
        this.date2_time = this.format_date(this.date2, "time");
      }
      if (this.date1 >= this.date2) {
        this.date2_date = this.getrans(this.current_tab.enddate_placeholder);
        this.date2_time = this.getrans(this.current_tab.endtime_placeholder);
      }

      if (this.date1) {
        this.last_clicked_date_start = this.date1;
      }

      if (this.date2) {
        this.last_clicked_date_end = this.date2;
      }
    },
    calendar_change() {
      if (this.date1 > this.date2) {
        this.date2 = null;
        this.last_clicked_date_end = false;
      }
    },
    strip(string) {
      return string.toLowerCase().replace(/\s+/g, "");
    },
    format_date_en_only(date, format_date) {
      const pad = (n, s = 2) => `${new Array(s).fill(0)}${n}`.slice(-s);
      if (date) {
        let dt = new Date(date);
        //let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let month = months[dt.getMonth()];

        let day =
          dt.getDate() +
          (dt.getDate() % 10 == 1 && dt.getDate() != 11
            ? "st"
            : dt.getDate() % 10 == 2 && dt.getDate() != 12
            ? "nd"
            : dt.getDate() % 10 == 3 && dt.getDate() != 13
            ? "rd"
            : "th");

        let hours = dt.getHours();
        let mins = dt.getMinutes();
        let year = dt.getFullYear();
        let dateString = "";
        if (format_date === "date") {
          dateString = `${month} ${day} ${year}`;
        }
        if (format_date === "time") {
          dateString = `${pad(hours)}:${pad(mins)}`;
        }
        return dateString;
      }
    },
    time_to_12h(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
    format_date(date, format_date) {
      const pad = (n, s = 2) => `${new Array(s).fill(0)}${n}`.slice(-s);
      if (date) {
        let dt = new Date(date);
        //let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let months = this.months_arr;
        let month = months[dt.getMonth()];

        let day = dt.getDate() + "";

        if (this.lang === "en") {
          day =
            dt.getDate() +
            (dt.getDate() % 10 == 1 && dt.getDate() != 11
              ? "st"
              : dt.getDate() % 10 == 2 && dt.getDate() != 12
              ? "nd"
              : dt.getDate() % 10 == 3 && dt.getDate() != 13
              ? "rd"
              : "th");
        }

        let hours = dt.getHours();
        let mins = dt.getMinutes();
        let year = dt.getFullYear();
        let dateString = "";
        if (format_date === "date") {
          dateString = `${month} ${day} ${year}`;
        }
        if (format_date === "time") {
          dateString = `${pad(hours)}:${pad(mins)}`;

          if (this.use12h === "true") {
            dateString = this.time_to_12h(dateString);
          }
        }
        return dateString;
      }
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    set_hour_options() {
      let starthour = 0;
      let endhour = 23;
      let i = starthour;
      for (i; i <= endhour; i++) {
        this.hour_options.push(this.pad(i, 2));
      }
    },
    get_correct_minutes(minutes) {
      const minutes_array = Math.ceil(60 / this.minute_step);
      let min = 0;
      for (let i = 0; i < minutes_array; i++) {
        const min_step = i + 1;
        const minute = this.minute_step * min_step;
        if (minute > minutes) {
          min = minute;
          break;
        }
      }
      return min;
    },
    set_date_if_no_date() {
      this.fasttrack_start_date = new Date();
      if (this.fasttrack_rules.length > 0) {
        const advanced_days = this.fasttrack_rules.find(
          (data) => data.label === "advance_booking_days"
        ) || { value: 0 };

        if (Number(advanced_days.value) > 0) {
          this.fasttrack_start_date.setDate(
            this.fasttrack_start_date.getDate() + Number(advanced_days.value)
          );
          this.fasttrack_start_date.setHours(0, 0, 0, 0);
        } else {
          let hours = 0;
          const hours_object = this.fasttrack_rules.find(
            (data) => data.label === "min_booking_hours"
          );

          if (hours_object && hours_object.value) {
            hours = Number(hours_object.value);
          }

          const time = this.get_correct_minutes(
            this.fasttrack_start_date.getMinutes()
          );

          if (time === 0) {
            hours++;
          }

          this.fasttrack_start_date.setHours(
            this.fasttrack_start_date.getHours() + hours,
            time,
            0,
            0
          );
        }
      } else {
        let hours = 0;
        const time = this.get_correct_minutes(
          this.fasttrack_start_date.getMinutes()
        );

        if (time === 0) {
          hours++;
        }

        this.fasttrack_start_date.setHours(
          this.fasttrack_start_date.getHours() + hours,
          time,
          0,
          0
        );
      }

      if (this.propsData.dateStart && !this.date1) {
        this.date1 = new Date(this.propsData.dateStart);
        this.date1.setSeconds(0, 0);
      } else if (
        this.current_tab.tab_label === "Fasttrack" &&
        this.fasttrack_rules.length > 0 &&
        !this.propsData.dateStart
      ) {
        let set_date = true;

        if (this.date1) {
          this.date1.setSeconds(0, 0);
        }

        if (
          this.date1 &&
          this.date1.getTime() > this.fasttrack_start_date.getTime()
        ) {
          set_date = false;
        }

        if (set_date) {
          const advanced_days = this.fasttrack_rules.find(
            (data) => data.label === "advance_booking_days"
          ) || { value: 0 };
          this.date1 = new Date();
          if (Number(advanced_days.value) > 0) {
            this.date1.setDate(
              this.date1.getDate() + Number(advanced_days.value)
            );
            this.date1.setHours(0, 0, 0, 0);
          } else {
            const time = this.get_correct_minutes(this.date1.getMinutes());
            let hours = 0;
            const hours_object = this.fasttrack_rules.find(
              (data) => data.label === "min_booking_hours"
            );

            if (hours_object && hours_object.value) {
              hours = Number(hours_object.value);
            }

            if (time === 0) {
              hours++;
            }

            this.date1.setHours(this.date1.getHours() + hours, time, 0, 0);
          }
        }
      } else if (!this.date1) {
        this.date1 = this.defaultTime();
        this.date1.setSeconds(0, 0);
      }

      this.last_clicked_date_start = this.date1;
    },
    set_date_if_no_date_end() {
      if (this.propsData.dateEnd) {
        this.date2 = new Date(this.propsData.dateEnd);
        this.last_clicked_date_end = this.date2;
      }
      if (this.date2) {
        return;
      }
      if (this.date1) {
        this.date2 =
          this.date1.getTime() + 60 * 1000 + Number(this.minute_step);
        this.last_clicked_date_end = this.date2;
      } else {
        //this.date2 = this.defaultTime();
      }
    },
    defaultTime() {
      // const today = new Date();

      //today.setHours(0, 0, 0, 0);
      //const hours = 12;
      // return new Date(today.getTime() + 60 * 1000 + Number(this.minute_step));
      const today = new Date();
      let mins = today.getMinutes();
      let ad = 0;
      if (mins > 30) {
        ad = (60 - mins) / 60;
      } else {
        ad = (30 - mins) / 60;
      }
      let addedHours = 0;

      if (this.tab_advance_booking_hours) {
        addedHours = this.tab_advance_booking_hours;
      }

      addedHours = addedHours + ad;

      return new Date(today.getTime() + addedHours * 3600 * 1000);
    },

    addHours(date, h) {
      date.setTime(date.getTime() + h * 60 * 60 * 1000);
      return date;
    },

    disabled_before_today_and_after(date) {
      if (
        this.current_tab.tab_label === "Fasttrack" &&
        this.fasttrack_start_date !== null &&
        this.fasttrack_rules.length > 0 &&
        this.fasttrack_rules[0].value > 0
      ) {
        return (
          date < this.fasttrack_start_date ||
          date > new Date(this.last_allowed_date)
        );
      }

      const today = this.defaultTime();
      today.setHours(0, 0, 0, 0);
      //const days_allowed = 365; // 1 year
      // return (
      //   date < today ||
      //   date > new Date(today.getTime() + days_allowed * 24 * 3600 * 1000)
      // );

      return date < today || date > new Date(this.last_allowed_date);
    },
    disabled_before_today_and_after_end(date) {
      const dateOffset = 24 * 60 * 60 * 1000 * 1;
      return (
        date < this.date1 - dateOffset ||
        date > new Date(this.last_allowed_date)
      );
    },

    not_before_now_plus_hours(date) {
      if (
        this.current_tab.tab_label === "Fasttrack" &&
        this.fasttrack_start_date !== null
      ) {
        date.setSeconds(0, 0);
        return date.getTime() < this.fasttrack_start_date.getTime();
      }

      const today = new Date();
      let mins = today.getMinutes();
      let ad = 0;
      if (mins > 30) {
        ad = (60 - mins) / 60;
      }
      let addedHours = 0;

      if (this.tab_advance_booking_hours) {
        addedHours = this.tab_advance_booking_hours;
      }

      addedHours = addedHours + ad;

      return date < new Date(today.getTime() + addedHours * 3600 * 1000);
    },

    not_before_now_plus_hours_end(date) {
      if (!this.date1) {
        return false;
      }
      const startdate =
        this.date1.getTime() + 60 * 1000 + Number(this.minute_step);
      const enddate = date;
      return enddate < startdate;
    },
    goto_page(url) {
      this.loading_icon = true;
      setTimeout(() => {
        this.loading_icon = false;
      }, 2000);
      window.location.href = url;
    },
    encode_query_data(data) {
      const ret = [];
      for (let d in data)
        ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
      return ret.join("&");
    },
    goto_results() {
      let error = false;
      let tab = this.current_tab;

      //localStorage.removeItem("cart");
      if (!this.date1_date || this.date1_date === this.getrans("Enter Date")) {
        this.date1_date_error = true;
        error = true;
      }

      if (!this.date1_time || this.date1_time === this.getrans("Enter Time")) {
        this.date1_time_error = true;
        error = true;
      }

      if (this.tab_show_enddate) {
        if (
          !this.date2_date ||
          this.date2_date === this.getrans("Enter Date")
        ) {
          this.date2_date_error = true;
          error = true;
        }

        if (
          !this.date2_time ||
          this.date2_time === this.getrans("Enter Time")
        ) {
          this.date2_time_error = true;
          error = true;
        }
      }

      if (this.tab_select_venue && this.venue_select === "0") {
        this.venue_select_error = true;
        error = true;
      }

      if (this.tab_show_enddate) {
        if (!this.date1) {
          error = true;
        }
        if (!this.date2) {
          error = true;
        }
      }

      if (this.lane_options.length === 0) {
        let current_tab_label = this.current_tab.tab_label.toLowerCase();
        let venue_data = this.init_data[current_tab_label];
        if (venue_data && venue_data.lane) {
          this.lane_selected = venue_data.lane[0].lane_id;
        }
      } else if (this.lane_options.length === 1) {
        this.lane_selected = this.lane_options[0].lane_id;
      } else if (this.lane_options.length > 1 && !this.lane_selected) {
        this.lane_select_error = true;
        error = true;
      }

      if (
        this.tab_show_enddate &&
        this.brandSlug == "HER" &&
        this.date1 &&
        this.date2
      ) {
        if (
          new Date(this.date2).getTime() - new Date(this.date1).getTime() <
          3600000
        ) {
          this.date2_time = "";
          this.date2_date = "";
          this.showModal_mindur = true;
          return false;
        }
      }

      if (error) {
        this.showModal_error = true;
      } else {
        const data = {
          date1_date: this.format_date_en_only(this.date1, "date"),
          date1_time: this.format_date_en_only(this.date1, "time"),
        };

        if (tab.enddate_label) {
          data["date2_date"] = this.format_date_en_only(this.date2, "date");
        }

        if (tab.endtime_label) {
          data["date2_time"] = this.format_date_en_only(this.date2, "time");
        }

        if (this.tab_select_venue) {
          data["venue_select"] = this.venue_select;
          let venue_name_option =
            this.$refs[
              `venue_select_name-${tab.tab_label}-${this.venue_select}`
            ];
          if (venue_name_option && venue_name_option[0]) {
            let ven_name = venue_name_option[0].getAttribute("data-venue-name");
            if (ven_name) {
              data["venue_select_name"] = ven_name;
            }
          }
          //return false;
        }

        if (!data["venue_select"] && tab.tab_label === "Parking") {
          data["venue_select"] = this.venue_data[0].venue_id;
          data["venue_select_name"] = this.venue_data[0].venue_name;
        }

        if (!data["venue_select"] && tab.tab_label === "Fasttrack") {
          data["venue_select"] = this.fasttrack_venues[0].venue_id;
          data["venue_select_name"] = this.fasttrack_venues[0].name;
        }

        if (!data["venue_select"] && tab.tab_label === "Lounge") {
          data["venue_select"] = this.lounge_venues[0].venue_id;
          data["venue_select_name"] = this.lounge_venues[0].venue_name;
        }

        if (tab.tab_label === "Fasttrack") {
          data["lane_id"] = this.lane_selected;
        }

        if (tab.adults_label) {
          data["adults_select"] = this.adults_select;
        }

        if (tab.children_label && tab.children_active === "Y") {
          data["children_select"] = this.children_select;
        }

        if (tab.infants_label && tab.infants_active === "Y") {
          data["infants_select"] = this.infants_select;
        }

        if (tab.senior_label && tab.senior_active === "Y") {
          data["senior_select"] = this.senior_select;
        }

        data["lang"] = this.lang;

        data["partner_id"] = "";
        data["partner_name"] = "";
        data["campaign_id"] = "";
        data["campaign_name"] = "";
        data["tracking_id"] = "";
        data["search_origin"] = window.location.hostname;

        const querystring = "?" + this.encode_query_data(data);

        if (this.emitSearch === "true") {
          data["date1"] = this.date1;
          data["date2"] = this.date2;
          const new_event = new CustomEvent("search-emit", {
            detail: {
              data: data,
            },
          });
          window.dispatchEvent(new_event);
          return false;
        }

        if (tab.results_url || tab.staging_url) {
          if (process.env.VUE_APP_ENVIROMENT_TYPE === "staging") {
            this.goto_page(tab.staging_url + querystring);
          } else {
            this.goto_page(tab.results_url + querystring);
          }
        } else {
          let domain = this.goto_domain;
          let uri = "";
          uri = this.goto_page_url;
          this.goto_page(domain + uri + querystring);
        }
      }
    },
    get_key_value(obj, key) {
      let for_return = "";
      if (obj) {
        obj.map((item) => {
          if (Object.keys(item)[0] === key) {
            for_return = Object.values(item)[0];
          }
        });
      }
      return for_return;
    },
    show_tab(evt, tab) {
      evt.stopPropagation();
      const current_tab = evt.target;
      const tabs_content_parent = evt.target.closest(".c-widget__inputs");
      if (evt.target.classList.contains("c-widget__row")) {
        tabs_content_parent
          .querySelectorAll(".c-widget__row")
          .forEach((elemment) => {
            elemment.classList.remove("active");
          });

        current_tab.classList.add("active");
        this.changeTabUpdate(tab);
      }
    },

    validate_data(evt) {
      if (!evt) return;
      let current_dropdown = evt.target.id;

      //console.log("current_dropdown", current_dropdown);
      //console.log("this.current_tab.tab_label", this.current_tab.tab_label);

      let booking_max_pax;
      if (this.current_tab.tab_label === "Fasttrack") {
        if (this.fasttrack_rules.length > 0) {
          this.fasttrack_rules.forEach((rule) => {
            if (rule.label === "booking_max_pax") {
              booking_max_pax = rule.value;
            }
          });
          if (!booking_max_pax) {
            booking_max_pax = 10;
          }
        } else {
          booking_max_pax = 10;
        }
      }

      if (this.current_tab.tab_label === "Lounge") {
        let venue_data = this.init_data["lounge"].venue;
        //console.log("venue_data", venue_data);
        //console.log("this.venue_select", this.venue_select);
        if (venue_data) {
          if (venue_data.length === 1) {
            this.venue_select = venue_data[0].venue_id || "0";
          }
          venue_data.map((venue) => {
            if (this.venue_select.toString() === venue.venue_id.toString()) {
              booking_max_pax = venue.max_pax_per_booking || 10;
            }
          });
        } else {
          booking_max_pax = 10;
        }
      }

      if (booking_max_pax) {
        if (current_dropdown === "adults_select") {
          if (
            Number(this.adults_select) +
              Number(this.children_select) +
              Number(this.infants_select) >
            booking_max_pax
          ) {
            this.adults_select =
              booking_max_pax -
              (Number(this.children_select) + Number(this.infants_select));
          }
        }

        if (current_dropdown === "venue_children") {
          if (
            Number(this.children_select) +
              Number(this.adults_select) +
              Number(this.infants_select) >
            booking_max_pax
          ) {
            this.children_select =
              booking_max_pax -
              (Number(this.adults_select) + Number(this.infants_select));
          }
        }

        if (current_dropdown === "venue_infants") {
          if (
            Number(this.infants_select) +
              Number(this.children_select) +
              Number(this.adults_select) >
            booking_max_pax
          ) {
            this.infants_select =
              booking_max_pax -
              (Number(this.children_select) + Number(this.adults_select));
          }
        }

        this.adult_count =
          booking_max_pax -
          (Number(this.children_select) + Number(this.infants_select));
        this.children_count =
          booking_max_pax -
          (Number(this.adults_select) + Number(this.infants_select));
        this.infant_count =
          booking_max_pax -
          (Number(this.children_select) + Number(this.adults_select));
      } else {
        this.adult_count = 10;
        this.children_count = 10;
        this.infant_count = 10;
        return;
      }
    },
    reset_validate_data() {
      let booking_max_pax;

      if (this.current_tab.tab_label === "Fasttrack") {
        if (this.fasttrack_rules.length > 0) {
          this.fasttrack_rules.forEach((rule) => {
            if (rule.label === "booking_max_pax") {
              booking_max_pax = rule.value;
            }
          });
          if (!booking_max_pax) {
            booking_max_pax = 10;
          }
        } else {
          booking_max_pax = 10;
        }
      }

      if (this.current_tab.tab_label === "Lounge") {
        let venue_data = this.init_data["lounge"].venue;
        //console.log('venue_data 2403:', venue_data.length);
        if (venue_data) {
          if (venue_data.length === 1) {
            this.venue_select = venue_data[0].venue_id || "0";
          }
          venue_data.map((venue) => {
            if (this.venue_select.toString() === venue.venue_id.toString()) {
              booking_max_pax = venue.max_pax_per_booking || 10;
            }
          });
        } else {
          booking_max_pax = 10;
        }
      }

      if (booking_max_pax) {
        if (Number(this.adults_select) >= booking_max_pax) {
          this.children_select = 0;
          this.infants_select = 0;
        }

        if (
          Number(this.adults_select) + Number(this.children_select) >=
          booking_max_pax
        ) {
          this.infants_select = 0;
          this.children_select = booking_max_pax - Number(this.adults_select);
        }

        if (
          Number(this.infants_select) +
            Number(this.children_select) +
            Number(this.adults_select) >
          booking_max_pax
        ) {
          this.infants_select =
            booking_max_pax -
            (Number(this.children_select) + Number(this.adults_select));
        }

        this.adult_count =
          booking_max_pax -
          (Number(this.children_select) + Number(this.infants_select));
        this.children_count =
          booking_max_pax -
          (Number(this.adults_select) + Number(this.infants_select));
        this.infant_count =
          booking_max_pax -
          (Number(this.children_select) + Number(this.adults_select));
      } else {
        this.adult_count = 10;
        this.children_count = 10;
        this.infant_count = 10;
      }
    },
    reset_dropdown(dropdown) {
      dropdown.innerHTML = "";
    },
    set_dropdown_options(dropdown, count) {
      for (let i = 0; i < count; i++) {
        const option = document.createElement("option");
        option.text = i;
        option.value = i;
        dropdown.options.add(option);
      }
    },
    setPropsData() {
      if (this.propsData.venueSelect) {
        this.venue_select = this.propsData.venueSelect;
      }
      if (this.propsData.hideVenueSelect) {
        this.hide_venue_select = this.propsData.hideVenueSelect;
      }
      if (this.propsData.laneSelect) {
        this.lane_selected = this.propsData.laneSelect;
      }

      if (this.propsData.adultsSelect) {
        this.adults_select = this.propsData.adultsSelect;
      }
      if (this.propsData.childrenSelect) {
        this.children_select = this.propsData.childrenSelect;
      }
      if (this.propsData.infantsSelect) {
        this.infants_select = this.propsData.infantsSelect;
      }
      if (this.propsData.seniorSelect) {
        this.senior_select = this.propsData.seniorSelect;
      }

      if (this.propsData.dateStart) {
        this.date1 = new Date(this.propsData.dateStart);
        this.date1_date = this.format_date(this.date1, "date");
        this.date1_time = this.format_date(this.date1, "time");
      }

      if (this.propsData.dateStart && this.propsData.dateEnd) {
        this.date2 = new Date(this.propsData.dateEnd);
        this.date2_date = this.format_date(this.date2, "date");
        this.date2_time = this.format_date(this.date2, "time");
      }

      this.last_clicked_date_start = this.date1;
      this.last_clicked_date_end = this.date2;
    },

    updated_tabs_data() {
      this.set_style(this.brandData.brand_product_styles);
      // Set initial banner
    },
    current_tab_data_loaded() {
      this.setPropsData();
      this.goto_domain = this.brandData.resulturl;
    },
  },
  mounted() {
    this.set_hour_options();
    this.user_selected_tab = this.activeTab;
    setTimeout(() => {
      if (this.propsData.lang) {
        this.loadLanguage(this.propsData.lang);
        this.lang = this.propsData.lang;
      }
      this.setPropsData();
    }, 0);
    let change_tab_func = () => {
      if (this.tabsData.length > 1) {
        const selected_tab = this.tabsData.find(
          (tab) =>
            tab.tab_label.toLowerCase() === this.user_selected_tab.toLowerCase()
        );
        this.changeTabUpdate(selected_tab);
        if (this.init_data[this.user_selected_tab.toLowerCase()]) {
          clearInterval(setInterval_change_tab_func);
          this.current_tab_data_loaded();
        }
      }
    };
    let setInterval_change_tab_func = setInterval(change_tab_func, 5);
  },
  updated() {
    this.updated_tabs_data();
    this.reset_validate_data();
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/normalise.scss";
@import "@/assets/sass/bodyStyle.scss";
@import "~vue2-datepicker/scss/index.scss";
</style>
<style>
.error-boarder {
  border-color: red !important;
}
</style>
<style lang="scss" scoped>
.c-widget {
  width: 100%;
  padding: 0;
  user-select: none;
  box-sizing: border-box;

  &__all-tabs {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  &__tab {
    // width: auto;
    padding: 0.5rem 1rem;
    line-height: 1.3;
    user-select: none;
    font-weight: 700;
    // min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tab--mod {
    // border: 1px solid #ddd;

    border-radius: var(--border_radius);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &__inputs {
    box-shadow: 0px 3px 6px #00000029;
    overflow: hidden;
  }

  &__inputs--mod {
    background: #fff;
    // border: 1px solid #ddd;
    margin-top: 2px;
    border-radius: var(--border_radius);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  &__row {
    width: 100%;
    $row: &;

    &-content {
      display: none;
    }

    &.active {
      #{$row}-content {
        display: flex;
        flex-direction: row;
      }
    }
  }

  &__tab--inactive {
    cursor: pointer;
    //background-color: var(--brand_color_2);
    // color: var(--tab_in_active_text_color);
    font-size: var(--tab_in_active_text_size);

    // background-color: #c0c0c0;
    background-color: #4578bc;
    color: #fff;
    transition: all 0.2s ease;

    &:hover {
      background-color: #2a5ea3;
    }
  }

  &__tab--active {
    cursor: default;
    // background-color: var(--brand_color_1);
    // color: var(--tab_active_text_color);
    font-size: var(--tab_active_text_size);

    background-color: #034a94;
    color: #fff;
    position: relative;
  }

  &__tab--active::after {
    content: url("~@/assets/svg/triangle-her.svg");
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -10px);
  }

  &__row-content {
    padding: 0.5rem;
  }

  &__label {
    width: calc(100% - 2rem);
    padding: 1rem 0.3rem;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    //border: 0px solid #fff;
    // border-right: 1px solid #ddd;
    // border: 1px solid #ddd;

    cursor: pointer;
    display: block;
    position: relative;
    box-sizing: border-box;
    color: #545454;
  }

  &__label-span {
    min-height: 1.5rem;
    display: block;
    width: 100%;
  }

  &__search-icon {
    width: 0.6em;
    height: 0.6em;
    position: absolute;
    right: 1rem;
    bottom: 2rem;
  }

  &__label--sm {
    max-width: 90px;
  }

  &__label--sm-time {
    max-width: 140px;
  }

  &__select,
  &__input {
    display: block;
    // border: 0px solid #fff;
    cursor: pointer;
    padding: 0.6rem 0.6rem;
    margin-top: 0.6rem;
    font-size: 1rem;
    width: calc(100% - 1.2rem);
    border: 1px solid #ddd;
  }

  &__button {
    font-size: 1rem;
    padding: 0.6rem 3.5rem;
    border: 0px solid #fff;
    cursor: pointer;
    // background-color: var(--brand_color_3);
    background-color: #034a94;

    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 1rem;
    margin: 1.6rem 1rem;
    align-self: flex-end;
    max-height: 2.5rem;

    span {
      display: none;
    }
  }

  &--error {
    color: #cc0000;

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cc0000;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cc0000;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: #cc0000;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: #cc0000;
    }
  }
}

.c-pickers {
  display: flex;
  flex-direction: column;
}

.c-widget__all-tabs--hide {
  display: none;
}

.MD .c-widget {
  &__row-content {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  &__label {
    min-width: 200px;
    max-width: calc(50% - 1rem);
  }

  &__button {
    width: 100%;
    max-width: calc(100% - 1.5rem);
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0.5rem auto;
    align-self: flex-end;
    justify-self: flex-end;

    img {
      display: none;
    }

    span {
      display: block;
    }
  }

  .c-widget__button {
    width: calc(100% - 1rem);
  }

  .c-widget__label-full-on-MD {
    width: calc(100% - 1rem);
    max-width: calc(100%);
  }

  .c-widget__search-icon {
    width: 0.6em;
    height: 0.6em;
    position: absolute;
    right: 1rem;
    bottom: 2rem;
  }

  .c-widget__tab {
    // padding: 1rem;
  }
}

.SM .c-widget {
  // border: 2px solid red;

  &__all-tabs {
    flex-direction: column;
    display: none;
  }

  &__row {
    flex-direction: column;

    &::before {
      content: attr(
        data-title
      ); // Instead of polluting the HTML with a duplicate set of tabs, we get the accordion using the data-title attribute.
      line-height: 50px;
      display: block;
      margin-top: 2px;
      // background-color: var(--brand_color_2);
      // color: var(--tab_in_active_text_color);
      background-color: #4578bc;
      color: #fff;

      font-size: var(--tab_in_active_text_size);
      border-radius: var(--border_radius);
      // font-size: 1.25rem;
      font-weight: 700;
      cursor: pointer;
    }

    &.active {
      &::before {
        // background-color: var(--brand_color_1);
        // color: var(--tab_active_text_color);
        font-size: var(--tab_active_text_size);
        cursor: default;

        background-color: #034a94;
        color: #fff;
      }

      flex-direction: column;
    }
  }

  &__row-content {
    flex-direction: column;

    &.active {
      flex-direction: column;
    }
  }

  &__row .c-widget__row-content {
    flex-direction: column;
  }

  &__row.active .c-widget__row-content {
    flex-direction: column;
  }

  &__label {
    padding: 0.5rem;
    margin: 0.5rem;
    width: calc(100% - 1rem);
  }

  .c-widget__label-span {
    min-height: 0;
    display: inline;
    width: 100%;
  }

  .c-widget__label--sm-time {
    max-width: 100%;
  }

  .c-widget__button {
    width: calc(100% - 2rem);
  }

  &__search-icon {
    bottom: 1.4rem;
  }

  &__label--sm {
    max-width: 100%;
  }

  &__button {
    max-width: 100%;
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0.5rem auto;

    img {
      display: none;
    }

    span {
      display: block;
    }
  }
}

.loading-icon {
  margin-right: 0.5rem;

  svg {
    fill: #fff;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.homepage {
  .c-widget {
    .c-widget__row-content {
      justify-content: space-between;
      padding: 1.5rem;
      flex-direction: row !important;
      flex-wrap: wrap;
    }

    .c-widget__inputs--mod {
      background-color: rgba(6, 95, 173, 0.9);
    }

    .c-widget__label {
      color: #fff;
      width: 100%;
      max-width: calc(100% - 150px);
      padding: 0rem 0rem;
      margin: 0.3rem;
      margin-top: 0.6rem;
    }

    .c-widget__label-span {
      font-size: 0.8571rem;
    }

    .c-widget__label.c-widget__label--sm-time {
      min-width: 120px;
      width: 120px;
      max-width: calc(30% - 0.5rem);
    }

    .c-widget__label-full-on-MD {
      width: calc(100%);
      max-width: 100%;
    }

    .c-widget__select,
    .c-widget__input {
      margin-top: 0rem;
      font-size: 0.875rem;
    }

    .c-widget__search-icon {
      width: 0.6em;
      height: 0.6em;
      position: absolute;
      right: 0.7rem;
      bottom: 0.9rem;
    }

    .c-widget__button {
      color: #fff;
      line-height: 1rem;
      font-weight: 500;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      display: inline-block;
      background-color: #4578bc;
      margin-bottom: 0rem;
      padding: 0.8rem 1rem;
      font-size: 1rem;
      margin-top: 1.2rem;
      width: auto;
      margin-left: 0.3rem;

      transition: all 0.2s ease;

      &:hover {
        background-color: #2a5ea3;
      }
    }
  }
}

.SM .c-widget {
  .c-widget__row-content {
    padding: 1rem;
  }

  .c-widget__select,
  .c-widget__input {
    margin-top: 0.3rem;
    font-size: 0.875rem;
  }

  .c-widget__all-tabs--hide-mobile {
    .c-widget__row::before {
      display: none;
    }
  }
}

label {
  cursor: default;
  pointer-events: none;

  select,
  input {
    cursor: pointer;
    pointer-events: all;
  }
}

.adult-child-infant-senior {
  display: flex;
  width: 100%;
  justify-content: space-between;

  label.c-widget__label {
    min-width: 0px !important;
  }
}
</style>
